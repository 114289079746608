import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthContextProvider } from './Context/AuthContext';
import { ThemeContextProvider } from './Context/ThemeContext';
import { BrowserRouter } from 'react-router-dom';
import { AxiosInterceptor } from './Helper/api_helper';
import { ElectionContextProvider } from './Context/ElectionContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.Fragment>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ThemeContextProvider>
        <AuthContextProvider>
          <ElectionContextProvider>
            <AxiosInterceptor>
              <App />
            </AxiosInterceptor>
          </ElectionContextProvider>
        </AuthContextProvider>
      </ThemeContextProvider>
    </BrowserRouter>
  </React.Fragment>,
);
