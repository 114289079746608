import { useThemeContext, defaultThemeValues } from 'Context/ThemeContext';

export const useTheme = () => {
  const { dispatch } = useThemeContext();
  const changeHTMLAttribute = (attribute, value) => {
    if (document.documentElement) document.documentElement.setAttribute(attribute, value);
    return true;
  };
  const setLocalStorage = (key, value) => {
    const theme = JSON.parse(localStorage.getItem('theme') || null);
    if (theme) {
      theme[key] = value;
      localStorage.setItem('theme', JSON.stringify(theme));
    } else {
      const defaultTheme = new Object(defaultThemeValues);
      defaultTheme[key] = value;
      localStorage.setItem('theme', JSON.stringify(defaultTheme));
    }
    return true;
  };
  const setScreenSize = (value) => {
    changeHTMLAttribute('data-mobileview', value);
    dispatch({ type: 'SET_SCREEN_SIZE', payload: value });
    setLocalStorage('screenSize', value);
  };
  const changeSideBarState = (value) => {
    changeHTMLAttribute('data-side-bar-sm', value);
    dispatch({ type: 'CHANGE_SIDEBAR_STATE', payload: value });
    // setLocalStorage('screenSize', value);
  };

  return {
    setScreenSize,
    changeSideBarState,
  };
};
