import { createContext, useReducer, useContext } from 'react';

const initialState = {
  election: null,
  nomination: null,
  fetchedElection: false,
  fetchedNomination: false,
};
export const ElectionContext = createContext();

export const electionReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ELECTION_DETAILS':
      return { ...state, election: action.payload, fetchedElection: true };
    case 'SET_NOMINATION_DETAILS':
      return { ...state, nomination: action.payload, fetchedNomination: true };
    case 'CLEAR_CONTEXT':
      return { ...initialState };
    default:
      return state;
  }
};

export const ElectionContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(electionReducer, initialState);
  return <ElectionContext.Provider value={{ ...state, dispatch }}>{children}</ElectionContext.Provider>;
};

export const useElectionContext = () => {
  const context = useContext(ElectionContext);

  if (!context) {
    throw new Error('useElectionContext must be used inside an ElectionContextProvider');
  }

  return context;
};
