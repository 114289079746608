import React, { lazy, Suspense } from 'react';
const SigninPage = lazy(() => import('Pages/Auth/SigninPage'));

const PublicRoutes = [
  {
    path: '/',
    element: (
      <Suspense>
        <SigninPage />
      </Suspense>
    ),
  },
];

export { PublicRoutes };
