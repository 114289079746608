import { createContext, useReducer, useEffect, useContext } from 'react';

export const ThemeContext = createContext();

export const defaultThemeValues = {
  screenSize: 'lg',
  isDarkMode: false,
  isSidebarOpen: false,
};

const themeReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SCREEN_SIZE':
      return { ...state, screenSize: action.payload };
    case 'CHANGE_LAYOUT_THEME':
      return { ...state, isDarkMode: action.payload };
    case 'CHANGE_SIDEBAR_STATE':
      return { ...state, isSidebarOpen: action.payload };
    case 'SET_VALUES':
      return { ...action.payload, isSidebarOpen: false };
    default:
      return state;
  }
};

export const ThemeContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(themeReducer, {});
  useEffect(() => {
    const theme = JSON.parse(localStorage.getItem('theme') || null);
    if (theme) {
      dispatch({ type: 'SET_VALUES', payload: theme });
    } else {
      dispatch({ type: 'SET_VALUES', payload: defaultThemeValues });
    }
  }, []);

  return <ThemeContext.Provider value={{ ...state, dispatch }}>{children}</ThemeContext.Provider>;
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw Error('useThemeContext must be used inside an tHEMEContextProvider');
  }
  return context;
};
