import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { PublicRoutes } from './PublicRoutes';
import { ProtectedRoutes } from './ProtectedRoutes';
const VerticalLayout = lazy(() => import('../Layouts/Protected/Protected'));
// const PageNotFound = lazy(() => import('Pages/Errors/PageNotFound'));
const AuthLayouts = lazy(() => import('../Layouts/PublicLayouts'));

const AllRoutes = () => (
  <React.Fragment>
    <Routes>
      <Route
        element={
          <Suspense>
            <AuthLayouts />
          </Suspense>
        }
      >
        <Route exact path="/">
          {PublicRoutes.map((item) => (
            <Route key={item.path} path={item.path} element={item.element} />
          ))}
        </Route>
      </Route>
      <Route
        element={
          <Suspense>
            <VerticalLayout />
          </Suspense>
        }
      >
        <Route exact path="/">
          {ProtectedRoutes.map((item) => (
            <Route key={item.path} path={item.path} element={item.element} />
          ))}
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  </React.Fragment>
);

export default AllRoutes;
